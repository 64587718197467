/*.dashboarddd-container {
  padding: 0;
  margin: 0;
}


























.navbar {
  background-color: #681751;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.logo-container img {
  height: 50px;
}

.nav-title {
  margin-left: 10px;
  font-size: 24px;
}

.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  margin: 0 15px;
}

.nav-links a {
  text-decoration: none;
  color: #333;
}

.contact-info {
  font-size: 16px;
  color: #555;
}
*/
.inner-banner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);
  height: 500px;
  position: relative;
}

.inner-banner__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.inner-banner__title {
  position: relative;
  font-size: 36px;
  margin-bottom: 10px;
}

.thm-breadcrumb {
  list-style: none;
  padding: 0;
  display: flex;
}

.thm-breadcrumb__item {
  display: inline;
  margin-right: 5px;
}

.thm-breadcrumb__link {
  color: white;
  text-decoration: none;
}

.thm-breadcrumb__link:hover {
  text-decoration: underline;
}

/* New About Section Styles */
.about-section {
  padding: 80px 0;
  background: linear-gradient(135deg, #eceaeb 0%, #eeeeee 100%);
  position: relative;
  overflow: hidden;
}

.about-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  align-items: center;
  gap: 50px;
}

.about-content {
  flex: 1;
  animation: fadeInLeft 1s ease-out;
}

.about-image {
  flex: 1;
  position: relative;
  animation: float 6s ease-in-out infinite;
}

.about-image-frame {
  width: 500px;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 20px 40px rgba(177, 82, 150, 0.2);
  position: relative;
}

.about-image-frame::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 2px solid #b15296;
  border-radius: 25px;
  animation: borderPulse 3s infinite;
}

.about-image-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.about-image-frame:hover img {
  transform: scale(1.05);
}

.about-title {
  font-size: 2.5em;
  color: #b15296;
  margin-bottom: 25px;
  position: relative;
  padding-bottom: 15px;
}

.about-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80px;
  height: 3px;
  background: #b15296;
  animation: widthAnim 3s infinite;
}

.about-text {
  font-size: 1.1em;
  line-height: 1.8;
  color: #666;
  margin-bottom: 30px;
}

.floating-shapes {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
}

.shape {
  position: absolute;
  border-radius: 50%;
  background: rgba(177, 82, 150, 0.1);
  animation: floatingShapes 15s linear infinite;
}

/* Animations */
@keyframes fadeInLeft {
  from {
      opacity: 0;
      transform: translateX(-50px);
  }
  to {
      opacity: 1;
      transform: translateX(0);
  }
}

@keyframes float {
  0%, 100% {
      transform: translateY(0);
  }
  50% {
      transform: translateY(-20px);
  }
}

@keyframes borderPulse {
  0%, 100% {
      transform: scale(1);
      opacity: 1;
  }
  50% {
      transform: scale(1.05);
      opacity: 0.5;
  }
}

@keyframes widthAnim {
  0%, 100% {
      width: 80px;
  }
  50% {
      width: 120px;
  }
}

@keyframes floatingShapes {
  0% {
      transform: translate(0, 0) rotate(0deg);
  }
  100% {
      transform: translate(100px, 100px) rotate(360deg);
  }
}






/*current no*/



.current-numbers {
  min-height: 70vh;
  background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);
  padding: 40px 0;
}

.container {
  padding: 40px; /* Reduced from 200px to give more reasonable spacing */
  max-width: 1200px; /* Increased from 800px to make it wider */
  margin: 0 auto;
  height: 100%;
}

.current-numbers h1 {
  text-align: center;
  margin-bottom: 40px;
  color: #333;
  font-size: 2.5rem;
}

.doctor-numbers {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px; /* Increased gap between cards */
  padding: 20px;
}

.doctor-number-card {
  text-align: center;
  padding: 30px;
  background-color: #f5f5f5;
  border-radius: 12px;
  width: 100%;
  max-width: 300px; /* Slightly increased from 250px */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.doctor-number-card:hover {
  transform: translateY(-5px);
}

/* Rest of your animations remain the same */
@keyframes blink {
  0%, 90% {
      transform: scale(1);
      background-color: #793a79;
      color: #fcf8fb;
  }
  95% {
      transform: scale(1.1);
      background-color: #fcf8fb;
      color: #793a79;
  }
  100% {
      transform: scale(1);
      background-color: #793a79;
      color: #fcf8fb;
  }
}

.current-number-highlight {
  animation: blink 3s ease-in-out infinite;
  border-radius: 8px;
  padding: 15px;
  display: inline-block;
  margin: 15px 0;
  font-size: 1.2rem;
  font-weight: bold;
}





















.doctor-numbers {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.doctor-number-card {
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.doctor-number-card:hover {
  transform: translateY(-5px);
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-container {
      flex-direction: column;
  }
  
  .about-image-frame {
      width: 100%;
      height: 300px;
  }
  
  .navbar {
      flex-direction: column;
      padding: 15px;
  }
  
  .nav-links {
      margin: 15px 0;
  }
  
  .contact-info {
      margin-top: 10px;
  }
}


.vision-mission {
  padding: 60px 0;
  background: linear-gradient(135deg, #fcf8fb 0%, #baafba 100%);
 
}

.vision-block,
.mission-block {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto 60px;
  padding: 0 20px;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeInUp 0.8s ease forwards;
}

.mission-block {
  animation-delay: 0.3s;
}

.content-card {
  flex: 1;
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  transform: translateY(0);
  transition: transform 0.3s ease;
}

.content-card:hover {
  transform: translateY(-5px);
}

.content-card h2 {
  font-size: 36px;
  font-weight: 700;
  color: #333;
  margin-bottom: 20px;
}

.blue-line {
  width: 60px;
  height: 4px;
  background: #007bff;
  margin-bottom: 20px;
  transition: width 0.3s ease;
}

.content-card:hover .blue-line {
  width: 80px;
}

.content-card p {
  font-size: 16px;
  line-height: 1.8;
  color: #666;
}

.image-card {
  flex: 1;
  height: 400px;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.15);
}

.image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.6s ease;
}

.image-card:hover img {
  transform: scale(1.05);
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(30px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@media (max-width: 991px) {
  .vision-block,
  .mission-block {
      flex-direction: column;
      gap: 30px;
  }

  .content-card,
  .image-card {
      width: 100%;
  }

  .image-card {
      height: 300px;
  }
}

@media (max-width: 768px) {
  .vision-mission {
      padding: 40px 0;
  }

  .content-card {
      padding: 30px;
  }

  .content-card h2 {
      font-size: 28px;
  }

  .content-card p {
      font-size: 14px;
  }
}




/*

.section-header {
  text-align: center;
  margin-bottom: 60px;
 
  margin-top: 50px;
  padding-bottom: 50px;
}
.section-header p {
  color: #fffbfb;
  font-size: 2.8rem;
  text-align: center;
  max-width: 700px;
  margin: 0 auto; 
}
.section-header h2 {
    font-size: 3.0rem;
    color: #0a0a0b;
    margin-bottom: 15px;
    position: relative;
    display: inline-block;
}
.section-header h2::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 80px;
    height: 3px;
    background: #772968;
    border-radius: 2px;
}
.section-header p {
    color: #fffbfb;
    font-size: 1.1rem;
}
.doctors-container {
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    padding: 0 20px;
}
.doctor-card {
    background: rgb(255, 255, 255);
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 10px 20px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.doctor-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 15px 30px rgba(0,0,0,0.15);
}

.doctor-image {
  position: relative;
  overflow: hidden;
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center; 
  transition: transform 0.5s ease;
}

.doctor-card:hover .doctor-image img {
  transform: scale(1.05); 
}

.social-links {
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    background: rgba(52, 152, 219, 0.9);
    padding: 15px;
    display: flex;
    justify-content: center;
    gap: 20px;
    transition: bottom 0.3s ease;
}

.doctor-card:hover .social-links {
    bottom: 0;
}

.social-links a {
    color: white;
    font-size: 1.2rem;
    transition: transform 0.3s ease;
}

.social-links a:hover {
    transform: scale(1.2);
}

.doctor-info {
    padding: 25px;
    text-align: center;
}

.doctor-info h3 {
    color: #2c3e50;
    font-size: 1.4rem;
    margin-bottom: 10px;
}

.speciality {
    color: #3498db;
    font-weight: 600;
    font-size: 1.1rem;
    display: block;
    margin-bottom: 15px;
}

.qualifications {
    margin: 15px 0;
}

.qualifications p {
    color: #666;
    margin: 5px 0;
    font-size: 0.9rem;
}

.appointment-btn {
    background: #3498db;
    color: white;
    border: none;
    padding: 12px 25px;
    border-radius: 25px;
    font-size: 1rem;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 15px;
}

.appointment-btn:hover {
    background: #2980b9;
}

@media (max-width: 768px) {
    .section-header h2 {
        font-size: 2rem;
    }

    .doctors-container {
      
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }
}

@media (max-width: 480px) {
    .section-header h2 {
        font-size: 1.8rem;
    }

    .doctor-image {
        height: 250px;
    }

    .doctor-info {
        padding: 20px;
    }

  .doctors-section {
    padding: 120px 0;
    
    
    background: linear-gradient(135deg, #eceaeb 0%, #eeeeee 100%) !important;
    background-color: transparent !important;
    text-align: center !important; 
    margin-left: 40px;
    margin-bottom: 50px;
    margin-top: 80px; 

}

.section-title {
  text-align: center !important; 
    margin-bottom: 60px;
    padding: 0 20px;
}

.section-title h2 {
    font-size: 80px;
    color: #ffffff; 
    margin-bottom: 20px;
    font-weight: 700;
    text-align: center !important; 
    position: relative;
    display: inline-block;
}

.section-title p {
    font-size: 18px;
    color: #ffffff;  
    max-width: 700px;
    margin: 0 auto;
    text-align: center !important; 
}

.doctors-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 30px;
    justify-items: center;
}

.doctor-card {
    width: 100%;
    max-width: 350px;
    background: #ffffff;
    height: 100px !important;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 15px 35px rgba(0,0,0,0.1);
    transition: all 0.3s ease;
    margin: 0 auto;
}

.doctor-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 20px 40px rgba(0,0,0,0.15);
}

.doctor-image {
  position: relative;
  overflow: hidden;
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-image img {
  width: 100%;
  height: 100%;
  object-fit: contain; 
  object-position: center; 
  transition: transform 0.5s ease;
}

.doctor-card:hover .doctor-image img {
  transform: scale(1.05); 
}

.doctor-info {
  padding: 30px;
  background: #ffffff;
}

.doctor-name {
  font-size: 24px;
  font-weight: 700;
  color: #1a1a1a;
  margin-bottom: 10px;
}

.doctor-qualification {
  font-size: 16px;
  color: #007bff;
  margin-bottom: 15px;
  font-weight: 600;
}

.doctor-specialty {
  font-size: 15px;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

.doctor-experience {
  font-size: 14px;
  color: #888;
  display: flex;
  align-items: center;
  gap: 5px;
}

.experience-icon {
  color: #007bff;
  font-size: 18px;
}

@keyframes fadeInUp {
  from {
      opacity: 0;
      transform: translateY(30px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .doctors-container {
      grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
      gap: 30px;
  }
}
*/













.section-header {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 60px;
  padding-bottom: 50px;
}

.section-header p {
  color: #fffbfb;
  font-size: 1.1rem;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

.section-header h2 {
  font-size: 3.0rem;
  color: #0a0a0b;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
}

.section-header h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: #772968;
  border-radius: 2px;
}

.doctors-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
}

.doctor-card {
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
}

.doctor-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0,0,0,0.15);
}

.doctor-image {
  position: relative;
  width: 100%;
  padding-top: 100%; /* Creates a 1:1 aspect ratio */
  overflow: hidden;
}

.doctor-image img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center top; /* Focuses on the top part of the image */
  transition: transform 0.5s ease;
}

.doctor-card:hover .doctor-image img {
  transform: scale(1.05);
}

.doctor-info {
  padding: 25px;
  text-align: center;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.doctor-info h3 {
  color: #2c3e50;
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.doctor-qualification {
  color: #3498db;
  font-weight: 600;
  margin: 8px 0;
}

.doctor-specialty {
  color: #666;
  margin: 8px 0;
}

.doctor-experience {
  margin-top: 10px;
  color: #2c3e50;
}

.experience-icon {
  color: #f1c40f;
  margin-right: 5px;
}

/* Responsive Adjustments */
@media (min-width: 1024px) {
  .doctor-image {
    padding-top: 120%; /* Taller images on larger screens */
  }
  
  .doctors-container {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .doctor-image {
    padding-top: 110%; /* Slightly shorter on tablets */
  }
  
  .doctors-container {
    grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  }
  
  .doctor-info h3 {
    font-size: 1.2rem;
  }
}

@media (max-width: 767px) {
  .doctor-image {
    padding-top: 100%; /* Square ratio on mobile */
  }
  
  .doctors-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .doctor-info {
    padding: 15px;
  }
  
  .doctor-info h3 {
    font-size: 1.1rem;
  }
  
  .doctor-qualification,
  .doctor-specialty {
    font-size: 0.9rem;
  }
  
  .section-header h2 {
    font-size: 2rem;
  }
  
  .section-header p {
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .doctor-image {
    padding-top: 100%; /* Maintain square ratio on smaller phones */
  }
  
  .doctors-container {
    grid-template-columns: 1fr; /* Single column on very small screens */
    max-width: 300px;
    margin: 0 auto;
  }
}


/*
.section-header {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 60px;
  padding-bottom: 50px;
}

.section-header p {
  color: #fffbfb;
  font-size: 1.1rem;
  text-align: center;
  max-width: 700px;
  margin: 0 auto;
}

.section-header h2 {
  font-size: 3.0rem;
  color: #0a0a0b;
  margin-bottom: 15px;
  position: relative;
  display: inline-block;
}

.section-header h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  width: 80px;
  height: 3px;
  background: #772968;
  border-radius: 2px;
}

.doctors-container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 30px;
}

.doctor-card {
  background: #ffffff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.doctor-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0,0,0,0.15);
}

.doctor-image {
  position: relative;
  overflow: hidden;
  height: 300px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.doctor-image img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  transition: transform 0.5s ease;
}

@media (max-width: 768px) {
  .doctor-image {
    height: 250px; 
  }
}

@media (max-width: 480px) {
  .doctor-image {
    height: 200px; 
  }
}
.doctor-card:hover .doctor-image img {
  transform: scale(1.05);
}

.social-links {
  position: absolute;
  bottom: -50px;
  left: 0;
  right: 0;
  background: rgba(52, 152, 219, 0.9);
  padding: 15px;
  display: flex;
  justify-content: center;
  gap: 20px;
  transition: bottom 0.3s ease;
}

.doctor-card:hover .social-links {
  bottom: 0;
}

.social-links a {
  color: white;
  font-size: 1.2rem;
  transition: transform 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.2);
}

.doctor-info {
  padding: 25px;
  text-align: center;
}

.doctor-info h3 {
  color: #2c3e50;
  font-size: 1.4rem;
  margin-bottom: 10px;
}

.speciality {
  color: #3498db;
  font-weight: 600;
  font-size: 1.1rem;
  margin-bottom: 15px;
}

.qualifications {
  margin: 15px 0;
}

.qualifications p {
  color: #666;
  margin: 5px 0;
  font-size: 0.9rem;
}

.appointment-btn {
  background: #3498db;
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-top: 15px;
}

.appointment-btn:hover {
  background: #2980b9;
}


@media (max-width: 1024px) {
  .section-header h2 {
    font-size: 2.5rem;
  }
  .doctors-container {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media (max-width: 768px) {
  .section-header h2 {
    font-size: 2rem;
  }
  .doctor-image {
    height: 250px;
  }
  .doctor-info {
    padding: 20px;
  }
  .section-header p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .section-header h2 {
    font-size: 1.8rem;
  }
  .section-header p {
    font-size: 0.9rem;
  }
  .doctor-image {
    height: 200px;
  }
  .doctor-info h3 {
    font-size: 1.2rem;
  }
  .speciality {
    font-size: 1rem;
  }
  .appointment-btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

@media (max-width: 768px) {
  .doctors-section {
      padding: 80px 0;
  }
  .section-title h2 {
      font-size: 36px;
  }
  .doctor-image {
      height: 300px;
  }
}*/





