.doctor-dashboard {
    padding: 20px;
    max-width: 1200px;
    margin: 0 auto;
}

.date-filter {
    margin-bottom: 20px;
}

.date-filter input {
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.appointments-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.appointment-card {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.message {
    padding: 10px;
    margin: 10px 0;
    border-radius: 4px;
    background-color: #f8f9fa;
    border: 1px solid #ddd;
}