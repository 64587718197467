
.dashboard-container {
  font-family: Arial, sans-serif;
  background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);
height: 90px !important;
  
}
/* Base navbar styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px; 
  background-color: #007bff;
  color: white;
  animation: slideIn 0.8s ease-out;
  border-radius: 8px;
  position: relative;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Logo styles */
.logo-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.logo-container img {
  width: 80px;
  height: 120px;
  margin-right: 10px;
}

/* Title styles */
.nav-title {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 900;
  font-size: 3.5em;
  animation: fadeIn 1s ease-in;
  letter-spacing: 2px;
  text-transform: uppercase;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Navigation links */
.nav-links {
  display: flex;
  gap: 30px;
  list-style: none;
  animation: slideRight 0.8s ease-out;
  padding: 0;
  margin: 0;
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  font-size: 1.2em;
}

.nav-links a:hover {
  color: #ffdd57;
}

/* Contact information */
.contact-info {
  font-weight: bold;
  animation: zoomIn 0.8s ease-out;
  margin-left: 20px;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.contact-info span {
  display: block;
  color: #575aff;
  font-size: 1.2em;
}

/* Responsive design */
@media screen and (max-width: 992px) {
  .navbar {
    padding: 15px;
  }
  
  .nav-title {
    font-size: 2.5em;
  }
  
  .logo-container img {
    width: 60px;
    height: 90px;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .logo-container {
    margin: 0 0 15px 0;
  }

  .nav-title {
    font-size: 2em;
    text-align: center;
    margin: 10px 0;
  }

  .nav-links {
    flex-direction: row; /* Keep links horizontal */
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: center;
    gap: 15px;
    width: 100%;
    margin: 15px 0;
  }

  .nav-links li {
    margin: 5px 10px;
  }

  .nav-links a {
    font-size: 1.1em;
    white-space: nowrap;
  }

  .contact-info {
    margin: 15px 0 0 0;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .navbar {
    padding: 10px;
  }

  .nav-title {
    font-size: 1.8em;
  }

  .logo-container img {
    width: 50px;
    height: 75px;
  }

  .nav-links {
    gap: 10px;
  }

  .nav-links a {
    font-size: 1em;
    padding: 5px;
  }
}






/*

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 60px; 
  background-color: #007bff;
  color: white;
  animation: slideIn 0.8s ease-out;
  border-radius: 8px;
  height: 100px; 
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo-container {
  display: flex;
  align-items: center;
  margin-right: 40px;
}

.logo-container img {
  width: 80px; 
  height: 120px; 
  margin-right: 10px;
  animation: rotate 1s ease-in-out infinite;
}


.nav-title {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  
  font-weight: bolder; 
  font-size: 3.5em; 
  animation: fadeIn 1s ease-in;
  letter-spacing: 2px;
  text-transform: uppercase; 
  font-weight: 900;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nav-links {
  display: flex;
  gap: 30px; 
  list-style: none;
  animation: slideRight 0.8s ease-out;
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  font-size: 1.2em; 
}

.nav-links a:hover {
  color: #ffdd57;
}

.contact-info {
  font-weight: bold;
  animation: zoomIn 0.8s ease-out;
  margin-left: 20px;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.contact-info span {
  display: block;
  color: #ffdd57;
  font-size: 1.2em; 
}

/*
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 40px;
  background-color: #007bff;
  color: white;
  animation: slideIn 0.8s ease-out;
  border-radius: 8px;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo-container {
  display: flex;
  align-items: center;
  margin-right: 40px; 

}

.logo-container img {
  width: 50px;
  height: 80px;
  margin-right: 10px;
  animation: rotate 1s ease-in-out infinite;
}

.nav-title {
  font-weight: bold;
  font-size: 1.8em;
  animation: fadeIn 1s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.nav-links {
  display: flex;
  gap: 20px;
  list-style: none;
  animation: slideRight 0.8s ease-out;
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  font-size: 1.1em;
}

.nav-links a:hover {
  color: #ffdd57;
}

.contact-info {
  font-weight: bold;
  animation: zoomIn 0.8s ease-out;
  margin-left: 20px; 
} 

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.contact-info span {
  display: block;
  color: #ffdd57;
  font-size: 1.1em;
}
*/










/* Carousel Styles */
.banner-one {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 70vh; /* Adjust as needed */
}

.item {
  display: flex;
  transition: transform 0.5s ease;
}

.banner-one__slide {
  min-width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-align: center;
}

.banner-one__tag-line {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.banner-one__title {
  font-size: 2.5em;
  margin: 10px 0;
}

.banner-one__text {
  font-size: 1.2em;
  margin: 15px 0;
}

.banner-one__btn {
  background-color: #ffdd57;
  color: #007bff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}

.banner-one__btn:hover {
  background-color: #ffd700;
}

/* Carousel Buttons */
.carousel-btn-block {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.carousel-btn {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel-btn:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Hero section title (h2) */
.hero-section h2 {
  font-size: 4em; /* Default font size for large screens */
  margin-bottom: 10px;
  font-weight: 800;
}

/* Hero section paragraph (p) */
.hero-section p {
  font-size: 3em; /* Default font size for large screens */
  font-weight: 700;
  margin-bottom: 20px;
}

/* Hero section */
.hero-section {
  color: rgb(35, 32, 35);
  text-align: center;
  padding: 0;
  margin: 0;
  transition: background 1s ease-in-out;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 90vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* Button Styles */
.hero-section .btn {
  display: inline-block;
  padding: 5px 10px;
  background-color: #762ba8;
  color: #fff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  font-size: 14px;
  transition: background-color 0.3s ease;
  width: auto;
  min-width: 100px;
  height: auto;
}

/* Button Hover Effect */
.hero-section .btn:hover {
  background-color: #570c3c;
  color: #fff;
}

/* Media Queries for Responsiveness */
@media screen and (max-width: 1200px) {
  .hero-section h2 {
      font-size: 3.5em; /* Slightly smaller title font for medium screens */
  }

  .hero-section p {
      font-size: 2.5em; /* Smaller subtitle font for medium screens */
  }

  .hero-section {
      height: 80vh; /* Adjust height for medium screens */
  }

  .btn {
      font-size: 16px; /* Larger button text for readability */
      padding: 8px 15px; /* Increase padding on medium screens */
  }
}

@media screen and (max-width: 992px) {
  .hero-section h2 {
      font-size: 3em; /* Reduce title font for tablets */
  }

  .hero-section p {
      font-size: 2em; /* Reduce subtitle font for tablets */
  }

  .hero-section {
      height: 70vh; /* Adjust height for tablets */
  }

  .btn {
      font-size: 15px; /* Reduce font size for the button */
      padding: 10px 20px; /* Increase padding for a more clickable button */
  }
}

@media screen and (max-width: 768px) {
  .hero-section h2 {
      font-size: 2.5em; /* Further reduce title font for smaller tablets */
  }

  .hero-section p {
      font-size: 1.8em; /* Reduce subtitle font for smaller tablets */
  }

  .hero-section {
      height: 60vh; /* Reduce height for smaller screens */
  }

  .btn {
      font-size: 14px; /* Adjust button text size */
      padding: 8px 15px; /* Adjust padding for smaller screens */
  }
}

@media screen and (max-width: 576px) {
  .hero-section h2 {
      font-size: 2em; /* Further reduce title font for mobile */
  }

  .hero-section p {
      font-size: 1.5em; /* Reduce subtitle font for mobile */
  }

  .hero-section {
      height: 50vh; /* Reduce height for mobile */
      padding: 0 10px; /* Add some padding to prevent text from touching screen edges */
  }

  .btn {
      font-size: 14px; /* Adjust button text size for mobile */
      padding: 12px 20px; /* Increase button size for easier tapping on mobile */
  }
}

@media screen and (max-width: 360px) {
  .hero-section h2 {
      font-size: 1.8em; /* Even smaller title font for very small screens */
  }

  .hero-section p {
      font-size: 1.4em; /* Even smaller subtitle font */
  }

  .hero-section {
      height: 45vh; /* Make the hero section shorter on very small screens */
  }

  .btn {
      font-size: 12px; /* Smaller button text on very small screens */
      padding: 10px 15px; /* Adjust padding for mobile-friendly button size */
  }
}






/*
.hero-section h2 {
    font-size: 4em; 
    margin-bottom: 10px;
     
     font-weight: 800;
    }

.hero-section p {
    font-size: 3em; 
    font-weight: 700;
    margin-bottom: 20px; 
}

/*
.hero-section {
    color: rgb(35, 32, 35);
    text-align: center;
    padding: 0; 
    margin: 0; 
    transition: background 1s ease-in-out; 
    background-size: cover; 
    background-position: center; 
    background-repeat: no-repeat; 
    height: 90vh; 
    display: flex;
    flex-direction: column; 
    justify-content: center; 
    align-items: center; 
}
/*
.hero-section .btn:hover {
    background-color: #570c3c; 
}


.hero-section .btn:hover {
    background-color: #570c3c; 
}

.btn {
    display: inline-block; 
    padding: 5px 10px; 
    background-color: #762ba8; 
    color: #fff; 
    border: none; 
    border-radius: 5px; 
    text-decoration: none; 
    font-size: 14px; 
    transition: background-color 0.3s ease; 
    width: auto; 
    min-width: 100px; 
    height: auto; 
    
}

.btn:hover {
  background-color: #570c3c; 
  color: #fff; 
}

*/

.cta-two {
    background-color: #420e4e; /* Light background color for contrast */
    padding: 30px 0; /* Vertical padding for spacing */
    text-align: center; /* Center the text */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    margin: -10px 0; /* Margin to create space above and below the section */
    height: auto;
    
}







.cta-two {
  padding: 3.5rem 1rem;
  background-color: #420e4e;
  text-align: center;
  }
  
  .container {
  max-width: 800px;
  margin: 0 auto;
  }
  
  .cta-two__title {
  margin: 0;
  color: #FFFFFF;
  font-size: 1.8rem; /* Decreased font size */
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  line-height: 1.4;
  animation: fadeInUp 1s ease;
  }
  
  .cta-two__highlight {
  font-weight: 600;
  color: #FFA500; /* Changed color to orange */
  transition: color 0.4s ease;
  animation: fadeInUp 1s ease 0.5s;
  animation-fill-mode: both;
  }
  
  .cta-two__link {
  color: #FFA500; /* Changed color to orange /
  display: inline-block;
  vertical-align: middle;
  line-height: 1em;
  border-bottom: 2px solid #FFA500; / Changed border color to orange */
  padding-bottom: 3px;
  transition: color 0.4s ease, border-color 0.4s ease;
  animation: fadeInUp 1s ease 1s;
  animation-fill-mode: both;
  }
  
  .cta-two__link:hover {
  color: #EB4343;
  border-color: #EB4343;
  }
  
  @media (max-width: 767px) {
  .cta-two {
  padding: 2.5rem 1rem;
  }
  
  .cta-two__title {
  font-size: 1.4rem; /* Decreased font size for smaller screens */
  }
  }







  .service-one .column-5__row {
    display: flex;
    flex-wrap: wrap;
    margin-left: -10px;
    margin-right: -10px;
  }

  .block-title__title {
    font-size: 5rem !important;
    margin-bottom: 50px;
    color: #572658;
    font-weight: bold;
    font-size:100px;
  }
  
  .service-one .column-5__col {
    flex: 1 1 20%; /* 20% width for 5 cards in a row */
    padding-left: 10px;
    padding-right: 10px;
    max-width: 20%;
  }


  .service-one {
    padding-top: 85px;
    padding-bottom: 90px;
    background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);
  }
  
  .service-one__single {
    width: 100%;
    border-radius: 5px;
    background-color: #e4edf4;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 30px;
    min-height: 220px;
    text-align: center;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  
  .service-one__single:hover {
    -webkit-box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
            box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.2);
    background-color: #fff;
  }
  
  .service-one .column-5__row {
    margin-left: -10px;
    margin-right: -10px;
  }
  
  .service-one .column-5__col {
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .service-one__icon {
    background-position: center center;
    width: 100%;
    height: 120px;
    background-repeat: no-repeat;
    background-color: #e4edf4;
    background-blend-mode: multiply;
    opacity: .6;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  
  .service-one__title {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    font-family: "Poppins", sans-serif;
  }
  
  .service-one__title a {
    color: #181E44;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
  }
  
  .service-one__title a:hover {
    color: #EB4343;
  }
  
  .service-one__link {
    color: #181E44;
    font-size: 20px;
    border-bottom: 1px solid #181E44;
    font-family: "Poppins", sans-serif;
    -webkit-transition: all .4s ease;
    transition: all .4s ease;
    display: inline-block;
    vertical-align: middle;
    margin-top: 20px;
  }
  
  .service-one__link:hover {
    color: #EB4343;
    border-color: #EB4343; 
  }
  
  .service-one_single:hover .service-one_icon {
    background-color: #fff;
  }
  @media (max-width: 768px) {
    .service-one .column-5__col {
      flex: 1 1 50%; /* 2 cards per row on smaller screens */
      max-width: 50%;
    }
  }
  
  @media (max-width: 576px) {
    .service-one .column-5__col {
      flex: 1 1 100%; /* 1 card per row on extra small screens */
      max-width: 100%;
    }
  }























/*

.container {
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 20px;
}

.service-one {
  padding-top: 80px;
  padding-bottom: 80px;
}

.block-title__title {
  font-size: 3.5rem;
  margin-bottom: 50px;
  color: #572658;
  font-weight: bold;
  font-size:100px;
}

.column-5__row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px; 
  margin: 0 -10px;
}

.column-5__col {
  flex: 0 0 calc(20% - 30px);
  max-width: calc(20% - 30px);
  padding: 10px;
}

.service-one__single {
  background: #EFF1F7;
  border-radius: 8px;
  padding: 60px 30px; 
  transition: transform 0.3s ease;
  height: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 250px; 

.service-one__single:hover {
  transform: translateY(-5px);
}

.service-one__icon {
  width: 180px; 
  height: 180px; 
  margin: 0 auto 25px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.6;
}

.service-one__title {
  margin: 0;
}

.service-one__title a {
  font-size: 1.2rem; 
  color: #333;
  text-decoration: none;
  font-weight: 600;
}

.service-one__link {
  display: inline-block;
  margin-top: 40px;
  font-size: 1.1rem;
  color: #666;
  text-decoration: none;
  max-width: 600px;
  line-height: 1.6;
}

@media (max-width: 1400px) {
  .column-5__col {
      flex: 0 0 calc(25% - 30px);
      max-width: calc(25% - 30px);
  }
  .service-one__icon {
      width: 130px;
      height: 130px;
  }
}

@media (max-width: 1200px) {
  .column-5__col {
      flex: 0 0 calc(33.333% - 30px);
      max-width: calc(33.333% - 30px);
  }
}

@media (max-width: 992px) {
  .column-5__col {
      flex: 0 0 calc(50% - 30px);
      max-width: calc(50% - 30px);
  }
  .service-one__icon {
      width: 120px;
      height: 120px;
  }
}

@media (max-width: 576px) {
  .column-5__col {
      flex: 0 0 100%;
      max-width: 100%;
  }
  .service-one__single {
      min-width: auto;
  }
  .service-one__icon {
      width: 100px;
      height: 100px;
  }
}


*/

.doctor-one {
  padding: 40px 10px; /* Reduce padding on smaller screens */
  background-color: #420e4e;
  position: relative;
  box-shadow: 0px 0px 54px 0px rgba(0, 0, 0, 0.15);
}

.doctor-one__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row; /* Default row for larger screens */
}

.doctor-one__person {
  width: 40%;
  max-width: 300px; /* Adjust max width for smaller screens */
  height: auto;
  animation-duration: 1s;
  animation-fill-mode: both;
  margin-right: 50px;
  transform: translateX(40px); /* Adjust to control movement */
}

.doctor-one__content {
  width: 55%;
  margin-left: 10px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.doctor-one__tag-line, .doctor-one__title, .doctor-one__text {
  color: #ffffff;
}

@media screen and (max-width: 1024px) {
  .doctor-one__person {
    margin-right: 30px;
    transform: translateX(20px); /* Less translate on tablets */
  }

  .doctor-one__content {
    width: 60%;
    margin-left: 0;
  }
}

@media screen and (max-width: 768px) {
  .doctor-one__wrapper {
    flex-direction: column; /* Stack items vertically */
    align-items: center;
  }

  .doctor-one__person {
    width: 80%;
    max-width: 250px;
    margin: 0 auto 20px auto; /* Center and add space below */
    transform: translateX(0); /* Remove translate on mobile */
  }

  .doctor-one__content {
    width: 90%;
    text-align: center; /* Center-align text for mobile */
  }

  .doctor-one__title {
    font-size: 1.5em;
  }

  .doctor-one__tag-line {
    font-size: 1.2em;
  }
}

@media screen and (max-width: 480px) {
  .doctor-one {
    padding: 20px 5px;
  }

  .doctor-one__title {
    font-size: 1.2em;
  }

  .doctor-one__tag-line {
    font-size: 1em;
  }

  .doctor-one__text {
    font-size: 0.9em;
  }
}

/* Existing animations */
@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

.animate__zoomIn {
  animation-name: zoomIn;
}

@keyframes slideInRight {
  from {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.animate__slideInRight {
  animation-name: slideInRight;
}

.animate__animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}




















/*
.doctor-one {
  padding: 60px 20px;
  background-color: #420e4e;
  position: relative;
  box-shadow: 0px 0px 54px 0px rgba(0, 0, 0, 0.15);
}

.doctor-one__wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.doctor-one__person {
  width: 40%;
  max-width: 400px;
  height: 500px;
  animation-duration: 1s;
  animation-fill-mode: both;
  margin-right: 100px; 
  transform: translateX(70px); 
}

.doctor-one__content {
  width: 55%;
  margin-left: 20px;
  animation-duration: 1s;
  animation-fill-mode: both;
}

.doctor-one__tag-line {
  font-size: 1.5em;
  color: #ffffff;
}

.doctor-one__title {
  font-size: 2em;
  margin: 10px 0;
  color: #ffffff;
}

.doctor-one__text {
  margin-bottom: 15px;
  color: #ffffff;
}
  @keyframes zoomIn {
    from {
      opacity: 0;
      transform: scale3d(0.3, 0.3, 0.3);
    }
  
    50% {
      opacity: 1;
    }
  }
  
  .animate__zoomIn {
    animation-name: zoomIn;
  }
  
  @keyframes slideInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .animate__slideInRight {
    animation-name: slideInRight;
  }
  
  .animate__animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }

  */



/* Existing CSS */


    .testimonial-one {
        margin-bottom: 40px; 
    padding: 80px 20px;
    background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);
  }
  
  .testimonial-one__single {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    margin-bottom: 30px;
  }
  
  .testimonial-one__text {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .testimonial-one__info {
    display: flex;
    align-items: center;
  }
  
  .testimonial-one__name {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }
  
  /* Animations */
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      transform: translate3d(-100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .animate__fadeInLeft {
    animation-name: fadeInLeft;
  }
  
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .animate__fadeInUp {
    animation-name: fadeInUp;
  }
  
  @keyframes fadeInRight {
    from {
      opacity: 0;
      transform: translate3d(100%, 0, 0);
    }
  
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
  
  .animate__fadeInRight {
    animation-name: fadeInRight;
  }












.containerrr{width: 100%; /* Full width of the screen */
  max-width: 100%; /* Remove the max-width limit */
  padding: 0 20px; /* Add padding for some spacing */
  margin: 0 auto;}





  .feature-one {
    padding: 60px 0;
    
    background-color: #fdfeff; /* Light background */
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05); /* Soft shadow for depth */
    border-radius: 15px; /* Rounded corners for a smoother look */
    margin: 20px auto;
  }
  
  /* Title Styling */
  .feature-one__box-title {
    font-size: 32px;
    font-weight: bold;
    color: #8a1c70; /* Soft purple title */
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  /* Paragraph Styling */
  .feature-one__content p {
    font-size: 18px;
    line-height: 1.7;
    color: #333; /* Dark gray for readability */
    margin-left: 20px;
    text-align: justify;
  }
  
  /* Increased Container Size */
  .container {
    max-width: 2400px; /* Increase container width to 1400px */
    margin: 0 auto;
    height:100px;
  }
  
  .row {
    display: flex;
    align-items: center;
  }
  
  /* Responsive Design for Smaller Screens */
  @media (max-width: 768px) {
    .row {
        flex-direction: column;
        text-align: center;
    }
  
    .feature-one__content p {
        margin-left: 0;
    }
  
    .feature-one__box-title {
        margin-bottom: 10px;
    }
  }




















  /* Existing CSS */

.google-map__home {
    width: 100%;
    height: 400px;
  }
  
  .site-footer {
    background-color: #420e4e;
    padding-top: 80px;
    padding-bottom: 30px;
  }
  
  .footer-col {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .footer-widget {
    flex-basis: 30%;
    margin-bottom: 50px;
  }
  
  .footer-widget__title {
    font-size: 20px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 20px;
  }
  
  .footer-widget__links-list,
  .footer-widget__time-list {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-widget__links-list-item,
  .footer-widget__time-list-item {
    margin-bottom: 10px;
  }
  
  .footer-widget__links-list-item a,
  .footer-widget__time-list-item a {
    color: #fffbfb;
    text-decoration: none;
  }
  
  .footer-widget__links-list-item a:hover,
  .footer-widget__time-list-item a:hover {
    color: #ffffff;
  }
  
  .footer-widget__social {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }




  .footer-widget__social a {
    display: inline-block;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;  /* To ensure the image fits inside the circle */
    margin-right: 10px;
    transition: all 0.3s ease;
  }
  
  .instagram-logo {
    width: 100%;
    height: 100%;
    object-fit: cover;  /* Ensures the image covers the whole circle */
  }
  /*
  .footer-widget__social a {
    display: inline-block;
    width: 40px;
    height: 40px;
    background-color: #655151;
    color: #ffffff;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    margin-right: 10px;
    transition: background-color 0.3s ease;
  }
  */
  .footer-widget__social a:hover {
    background-color: #f3eaea;
  }
  
  .bottom-footer {
    border-top: 1px solid #f5f2f2;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  
  .bottom-footer__line {
    border-color: #f3f0f0;
    margin: 0;
  }
  
  .bottom-footer__wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .bottom-footer__text {
    color: #efebeb;
    margin: 0;
  }
  .footer-widget__time-list-item {
    color: white;
}













.map-container {
  max-width: 100%;
  margin: 20px auto;
  padding: 15px;
  text-align: center;
  font-family: Arial, sans-serif;
}

.google-map {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%; /* 16:9 Aspect Ratio */
  margin: 15px 0;
}

.google-map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
}

.address {
  color: #666;
  margin-top: 10px;
}

h2 {
  color: #333;
  margin-bottom: 15px;
}




/*
@media (max-width: 768px) {
  .nav-links {
      display: none;
      flex-direction: column;
      background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);
      position: absolute;
      top: 60px;
      right: 0;
      width: 100%;
      padding: 1rem 2rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links-active {
      display: flex;
  }

  .menu-toggle {
      display: block;
  }*/
