.dashboard-container {
  font-family: Arial, sans-serif;
  background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);

  
}
/* Base navbar styles */
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px; 
  background-color: #007bff;
  color: white;
  animation: slideIn 0.8s ease-out;
  border-radius: 8px;
  position: relative;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Logo styles */
.logo-container {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.logo-container img {
  width: 80px;
  height: 120px;
  margin-right: 10px;
}

/* Title styles */
.nav-title {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 900;
  font-size: 3.5em;
  animation: fadeIn 1s ease-in;
  letter-spacing: 2px;
  text-transform: uppercase;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

/* Navigation links */
.nav-links {
  display: flex;
  gap: 30px;
  list-style: none;
  animation: slideRight 0.8s ease-out;
  padding: 0;
  margin: 0;
}

@keyframes slideRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.nav-links li {
  margin: 0;
}

.nav-links a {
  color: white;
  text-decoration: none;
  transition: color 0.3s ease-in-out;
  font-size: 1.2em;
}

.nav-links a:hover {
  color: #ffdd57;
}

/* Contact information */
.contact-info {
  font-weight: bold;
  animation: zoomIn 0.8s ease-out;
  margin-left: 20px;
}

@keyframes zoomIn {
  from {
    opacity: 0;
    transform: scale(0.8);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.contact-info span {
  display: block;
  color: #ffdd57;
  font-size: 1.2em;
}

/* Responsive design */
@media screen and (max-width: 992px) {
  .navbar {
    padding: 15px;
  }
  
  .nav-title {
    font-size: 2.5em;
  }
  
  .logo-container img {
    width: 60px;
    height: 90px;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: column;
    align-items: center;
    padding: 15px;
  }

  .logo-container {
    margin: 0 0 15px 0;
  }

  .nav-title {
    font-size: 2em;
    text-align: center;
    margin: 10px 0;
  }

  .nav-links {
    flex-direction: row; /* Keep links horizontal */
    flex-wrap: wrap; /* Allow wrapping */
    justify-content: center;
    gap: 15px;
    width: 100%;
    margin: 15px 0;
  }

  .nav-links li {
    margin: 5px 10px;
  }

  .nav-links a {
    font-size: 1.1em;
    white-space: nowrap;
  }

  .contact-info {
    margin: 15px 0 0 0;
    text-align: center;
  }
}

@media screen and (max-width: 480px) {
  .navbar {
    padding: 10px;
  }

  .nav-title {
    font-size: 1.8em;
  }

  .logo-container img {
    width: 50px;
    height: 75px;
  }

  .nav-links {
    gap: 10px;
  }

  .nav-links a {
    font-size: 1em;
    padding: 5px;
  }
}




/*.services-container {
    padding: 0;
    margin: 0;
  
  .navbar {
    background-color: #c448a1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo-container img {
    height: 50px;
  }
  
  .nav-title {
    margin-left: 10px;
    font-size: 24px;
  }
  
  .nav-links {
    list-style: none;
    display: flex;
  }
  
  .nav-links li {
    margin: 0 15px;
  }
  
  .nav-links a {
    text-decoration: none;
    color: #333;
  }
  
  .contact-info {
    font-size: 16px;
    color: #555;
  }
  */




















  .services-content {
    margin-top: 20px;
  }
  
  .service-list {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
  }
  
  .service-item {
    background-color: #f1f1f1;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 5px;
  }
  
  

  .inner-banner {
    display: flex; /* Use flexbox for alignment */
    flex-direction: column; /* Stack children vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    padding: 50px 0;
    color: white;
    height: 500px; /* Set a specific height for the inner banner */
    position: relative; /* Required for absolute positioning */
}

.inner-banner__image {
    position: absolute; /* Position the image absolutely within the banner */
    top: 0;
    left: 0;
    width: 100%; /* Make the image full width */
    height: 100%; /* Make the image full height */
    object-fit: cover; /* Cover the entire area */
    z-index: -1; /* Send the image behind other content */
}

.inner-banner__title {
    position: relative; /* Position the title relative for z-index */
    font-size: 36px;
    margin-bottom: 10px;
}

.thm-breadcrumb {
    list-style: none;
    padding: 0;
    display: flex; /* Use flexbox for breadcrumb alignment */
}

.thm-breadcrumb__item {
    display: inline;
    margin-right: 5px;
}

.thm-breadcrumb__link {
    color: white;
    text-decoration: none;
}

.thm-breadcrumb__link:hover {
    text-decoration: underline;
}











.service-two {
    background-color: #eaeaea; /* Light background for contrast */
    padding: 60px 20px; /* Add padding for better spacing */
    text-align: center; /* Center align the text */
    opacity: 0; /* Initially hidden */
    transform: translateX(-100%); /* Start off the screen to the left */
    transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition */
    height: auto;
}

.service-two.animate {
    opacity: 1; /* Make visible */
    transform: translateX(0); /* Slide into place */
}

.block-title {
    margin-bottom: 30px; /* Spacing below the title */
}

.block-title__title {
    font-size: 2.5rem; /* Adjusted title size */
    font-weight: bold; /* Bold title */
    color: #6a5acd; /* Purple color for the title */
}


.container {
    max-width: 1200px; /* Set a max width for the container */
    margin: 0 auto; /* Center the container */
    padding: 0 20px; /* Add padding for better spacing */
}

.block-title__text {
    font-size: 1.2rem; /* Text size */
    color: #555; /* Darker text for better readability */
    line-height: 1.6; /* Improved line height */
    max-width: 100%; /* Allow it to use full width */
    margin: 0; /* Remove margin if any */
    padding: 0; /* Remove padding */
    text-align: justify; /* Justify text for alignment */
}

















/* Services.css */

.services-container {
  background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);
  }
  
  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .logo-container {
    display: flex;
    align-items: center;
  }
  
  .logo-container img {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
  
  .nav-title {
    font-size: 24px;
    font-weight: 600;
    color: #333;
  }
  
  .nav-links {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .nav-links li {
    margin-right: 20px;
  }
  
  .nav-links li a {
    text-decoration: none;
    color: #333;
    font-weight: 500;
  }
  
  .contact-info {
    font-size: 16px;
    color: #333;
  }
  
  .inner-banner {
    position: relative;
    height: 300px;
    overflow: hidden;
  }
  
  .inner-banner__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .inner-banner__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 36px;
    font-weight: 600;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  }
  
  .thm-breadcrumb {
    position: absolute;
    bottom: 20px;
    left: 20px;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .thm-breadcrumb__item {
    margin-right: 10px;
    font-size: 14px;
    color: #fff;
  }
  
  .thm-breadcrumb__item.current {
    font-weight: 600;
  }
  
  .thm-breadcrumb__link {
    text-decoration: none;
    color: #fff;
  }
  



  .service-two {
    padding: 80px 0;
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s, transform 0.6s;
  }
  
  .service-two.animate {
    opacity: 1;
    transform: translateY(0);
  }
  
  .block-title__title {
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .block-title__text {
    font-size: 16px;
    color: #666;
    line-height: 1.6;
  }




 


.service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}







  
  .service-section {
    padding: 80px 10px;
  }
  
  .service-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 30px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    margin: 20px 0; /* Added vertical margin for space above and below each card */
}
  
  .service-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
  }
  
  .service-card__icon {
    margin-bottom: 20px;
  }
  
  .service-card__icon img {
    width: 60px;
    height: 60px;
  }
  
  .service-card__title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
  }
  
  .service-card__text {
    font-size: 16px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .service-card__link {
    text-decoration: none;
    color: #7b1fa2;
    font-weight: 500;
  }
  
  .service-card__link:hover {
    color: #58117a;
  }











  /* Keyframes for the fade-in and slide-up animation */
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translateY(20px); /* Start slightly lower */
    }
    100% {
        opacity: 1;
        transform: translateY(0); /* End at original position */
    }
}

.block-title {
    animation: fadeInUp 0.8s ease forwards; /* Apply the animation */
    opacity: 0; /* Ensure it's hidden initially */
    transform: translateY(20px); /* Start position for animation */
}

.block-title {
    margin-bottom: 50px;
    text-align: center;
}

.block-title__title {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin-bottom: 20px;
}

.block-title__texttt {
    font-size: 1.1rem;
    color: #fafafa;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
}





/*

@media (max-width: 768px) {
  .nav-links {
      display: none;
      flex-direction: column;
      background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);
      position: absolute;
      top: 60px;
      right: 0;
      width: 100%;
      padding: 1rem 2rem;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .nav-links-active {
      display: flex;
  }

  .menu-toggle {
      display: block;
  }
}*/
