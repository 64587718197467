/* Page Background Color */
html, body {
    margin: 0;
    padding: 0;
    min-height: 100vh;
    background: linear-gradient(135deg, #f7f7f7 0%, #793a79 100%)!important;
    
}
.appointment-form-container {
    max-width: 600px;
    margin: 0 auto;
    background: rgb(91, 16, 91);
    
    padding: 20px;
   /* background: linear-gradient(135deg, #fcf8fb 0%, #793a79 100%);*/
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.appointment-form-container h2 {
    text-align: center;
    color: #f8f6f6;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #f5f1f1;
    font-weight: 500;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
}

.form-group textarea {
    height: 100px;
    resize: vertical;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #420e33;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s;
}

button:hover {
    background-color: #911279;
}

button:disabled {
    background-color: #904343;
    cursor: not-allowed;
}

.message {
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 4px;
    text-align: center;
    font-size: 20px;
    background-color: #ff0000 !important;; /* Changed to red */
    color: white;
}

.message.error {
    background-color: #ab3e47;
    color: #f5efef;
}

@media (max-width: 768px) {
    .appointment-form-container {
        max-width: 100%;
        margin: 10px;
    }
}

.previous-appointments {
    margin: 20px 0;
    padding: 15px;
    background: #f5f5f5;
    border-radius: 5px;
}

.appointments-list {
    display: grid;
    gap: 10px;
    margin-top: 10px;
}

.appointment-item {
    background: white;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.patient-appointments {
    margin: 20px 0;
    padding: 15px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.1);
}

.appointments-timeline {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 15px;
}